<template>
  <el-container ref="container">
    <el-header ref="filterHeader" height="auto"></el-header>
    <el-main>
      <el-card :style="'height:' + clientHeight + 'px;overflow-y:scroll'">
        <template #header class="clearfix">
          <span v-if="roles && roles.length>0">{{roles[0].name}}</span>
          <el-button style="float: right;margin-right:15px; padding: 3px 0" type="text" @click="cancelCheckAll()">取消全选</el-button>
          <el-button style="float: right;margin-right:15px; padding: 3px 0" type="text" @click="checkAll()">全选</el-button>
        </template>
        <el-row :gutter="10">
          <el-col :span="4"><span>菜单：</span></el-col>
          <el-col :span="10"><span>菜单对应权限：</span></el-col>
          <el-col :span="10"><span>其他权限：</span></el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4">
            <el-tree ref="menuTree"
              :props="props"
              highlight-current
              :data="groupMenus"
              node-key="id"
              :expand-on-click-node = "false"
              @node-click="handleClick"
              style="width:100%;overflow:auto">
            </el-tree>
          </el-col>
          <el-col :span="10">
            <div class="grid-content bg-purple">
              <el-table
                ref="apiTable"
                row-key="id"
                border
                fit
                size="mini"
                highlight-current-row
                :data="menuApis"
                @row-click="onRowClick"
                @select="onRowSelect"
                @select-all="onAllRowSelect"
                style="width:100%;overflow:auto"
              >
                <!-- <el-table-column label="序号" type="index" width="100" align="center">
                  </el-table-column> -->
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column label="权限名称" width="160" align="center">
                  <template #default="scope">
                    <span>{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Rest" min-width="200" align="center" header-align="center">
                  <template #default="scope">
                    <span >{{scope.row.rest}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="grid-content bg-purple">
              <el-table
                ref="otherApiTable"
                row-key="id"
                border
                fit
                size="mini"
                highlight-current-row
                :data="otherApis"
                @row-click="onRowClickOther"
                @select="onRowSelectOther"
                @select-all="onAllRowSelectOther"
                style="width:100%;overflow:auto"
              >
                <!-- <el-table-column label="序号" type="index" width="100" align="center">
                  </el-table-column> -->
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column label="权限名称" width="160" align="center">
                  <template #default="scope">
                    <span>{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Rest" min-width="200" align="center" header-align="center">
                  <template #default="scope">
                    <span >{{scope.row.rest}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-main>
    <el-footer ref="footer" height="30px">
      <el-row type="flex" align="middle">
        <el-col style="width:124px;"></el-col>
        <el-col>
          <el-button v-if="selectedRoleId" size="mini" type="primary" @click="handleSubmit" v-waves>保存</el-button>
          <el-button v-if="showCancel" size="mini" v-waves @click="handleCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<style>
#permissionTab .el-tabs__header.is-left {
  float: none;
  margin-right: 20px;
}
.el-tree-node > .el-tree-node__children{
  overflow:initial;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
</style>

<script>
import waves from '@/directive/waves' // 水波纹指令
import rolePermission from '@/apis/role/rolePermission'
import { mapGetters } from 'vuex'

export default {
  directives: {
    waves
  },
  computed: {
    ...mapGetters(['mainContainerHeight']),
    loadSuccess () {
      return this.otherApiLoadSuccess && this.roleLoadSuccess
    }
  },
  watch: {
    mainContainerHeight: function (newHeight, oldHeight) {
      this.resize()
    },
    loadSuccess: function (val) {
      if (val) {
        this.loadCheckStatus()
        this.otherCheckStatus()
      }
    }
  },
  data () {
    return {
      otherApiLoadSuccess: false,
      roleLoadSuccess: false,
      clientHeight: 0,
      groupMenus: [],
      groupApis: [],
      menus: [],
      menuApis: [],
      otherApis: [],
      allApis: [],
      menuApiIds: [],
      currentMenu: null,
      // selectedMenuRows: [],
      checkedNodes: [],
      unCheckedNodes: [],
      selectedApis: [],
      selectedOtherApis: [],
      roles: [],
      selectedRoleId: undefined,
      selectedTab: undefined,
      showCancel: false,
      props: {
        children: 'children',
        label: 'title'
      }
    }
  },
  methods: {
    /**
     * 全选
     */
    checkAll () {
      this.getAllApis(this.groupMenus)
      this.selectedApis = this.groupApis
      this.selectedOtherApis = this.otherApis
      this.$refs.apiTable.clearSelection()
      this.$refs.apiTable.toggleAllSelection()
      this.$refs.otherApiTable.clearSelection()
      this.$refs.otherApiTable.toggleAllSelection()
      // setTimeout(() => {
      //   this.$refs.menuTree.setCheckedNodes(this.groupMenus)
      //   this.selectedMenuRows = this.$refs['menuTree'].getCheckedNodes(false, true)
      // }, 10)
    },
    /**
     * 取消全选
     */
    cancelCheckAll () {
      // this.selectedMenuRows = []
      this.selectedApis = []
      this.selectedOtherApis = []
      this.$refs.apiTable.clearSelection()
      this.$refs.otherApiTable.clearSelection()
      this.$refs.menuTree.setCheckedNodes([])
    },
    /**
     * 递归获取所有API
     */
    getAllApis (groupMenus) {
      groupMenus.forEach(gm => {
        if (Array.isArray(gm.permissions) && gm.permissions.length > 0) {
          this.groupApis = this.groupApis.concat(gm.permissions.filter(p => p.type.type === '认证授权'))
        }
        if (Array.isArray(gm.children) && gm.children.length > 0) {
          this.getAllApis(gm.children)
        }
      })
    },

    handleMenuCheckChange (data, status) {
      // this.selectedMenuRows = this.$refs['menuTree'].getCheckedNodes(false, true)
      this.handleClick(data)
    },
    /**
     * 取消按钮点击
     */
    handleCancel () {
      this.$router.replaceBy(this.$router.routesConfig.roleList)
    },
    /**
     * 提交按钮点击
     */
    handleSubmit () {
      var curRole = this.roles[0]
      // curRole.menus = this.selectedMenuRows
      curRole.permissions = this.unique([...this.selectedApis, ...this.selectedOtherApis])
      rolePermission.updateRole(curRole).then(response => {
        this.$message({
          title: '成功',
          message: '角色授权成功',
          type: 'success'
        })
        this.$router.replaceBy(this.$router.routesConfig.roleList)
      })
    },
    /**
     * 行被单击
     */
    onRowClick (row, column, event) {
    },
    onRowSelect (selection, row) {
      this.handleApiChange(selection)
    },
    onAllRowSelect (selection) {
      this.handleApiChange(selection)
    },

    /**
     * API选项变化事件处理
     * @param  {Array} selection 选择的行
     */
    handleApiChange (selection) {
      this.selectedApis = this.selectedApis.filter(sa => this.menuApis.filter(ma => ma.id === sa.id) <= 0)
      this.selectedApis = [...new Set([...this.selectedApis, ...selection])]
      if (this.currentMenu) {
        if (this.currentMenu.children && this.currentMenu.children.length > 0) {
          if (selection.length === 0) {
            this.filterAllMenus(this.currentMenu)
            this.checkedNodes.forEach(node => this.$refs.menuTree.setChecked(node, false))
          } else {
            this.filterAllMenusBySelection(this.currentMenu, selection)
            this.checkedNodes.forEach(node => this.$refs.menuTree.setChecked(node, true))
            if (this.unCheckedNodes && this.unCheckedNodes.length > 0) {
              this.unCheckedNodes.forEach(node => this.$refs.menuTree.setChecked(node, false))
            }
          }
        } else {
          this.$refs.menuTree.setChecked(this.currentMenu.id, selection.length !== 0)
        }
      }
      // this.selectedMenuRows = this.$refs['menuTree'].getCheckedNodes(false, true)
      this.checkedNodes = []
      this.unCheckedNodes = []
    },
    /**
     * 行被单击
     */
    onRowClickOther (row, column, event) {
    },
    onRowSelectOther (selection, row) {
      this.handleOtherApiChange(selection)
    },
    onAllRowSelectOther (selection) {
      this.handleOtherApiChange(selection)
    },
    handleOtherApiChange (selection) {
      this.selectedOtherApis = selection
    },
    /**
     * 递归获取当前节点所有菜单的id
     */
    filterAllMenus (menu) {
      this.checkedNodes = this.checkedNodes.concat(menu.id)
      if (menu.children && menu.children.length > 0) {
        menu.children.forEach(c => this.filterAllMenus(c))
      }
    },
    /**
     * 递归获取当前节点下被选中API对应的菜单
     */
    filterAllMenusBySelection (menu, selection) {
      if (menu.children && menu.children.length > 0) {
        menu.children.forEach(c => this.filterAllMenusBySelection(c, selection))
      } else {
        let select = menu.permissions.filter(p => selection.filter(s => p.id === s.id).length > 0)
        if (select && select.length > 0) {
          this.checkedNodes = this.checkedNodes.concat(menu.id)
        } else {
          this.unCheckedNodes = this.unCheckedNodes.concat(menu.id)
        }
      }
    },
    /**
     * 点击菜单
     */
    handleClick (menus) {
      this.groupApis = []
      this.getAllApis([menus])
      this.menuApis = this.unique(this.groupApis)
      this.currentMenu = menus
      if (this.menuApis.length > 0) {
        this.$nextTick(() => {
          this.menuApis.filter(ma => this.selectedApis.filter(sa => ma.id === sa.id).length > 0).forEach(row => {
            this.$refs.apiTable.toggleRowSelection(row, true)
          })
        })
      }
    },
    /**
     * 数组去重
     */
    unique (arr) { // 根据唯一标识id来对数组进行过滤
      let res = new Map() // 定义常量 res,值为一个Map对象实例
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
    /**
     * 递归获取所有API
     */
    menusHideable (groupMenus) {
      groupMenus.forEach(gm => {
        if (gm.type === 'Hideable') {
          gm.title = gm.title + '（隐藏）'
        }
        if (Array.isArray(gm.children) && gm.children.length > 0) {
          this.menusHideable(gm.children)
        }
      })
    },
    /**
     * 获取菜单列表
     */
    ready () {
      rolePermission.getAllMenuWithoutSuperMenu().then(res => {
        // this.groupMenus = res.data.filter(menu => menu.clientType.type === 'PC端')
        this.groupMenus = res.data
        this.menusHideable(this.groupMenus)
      })
      rolePermission.getNoMenuApis().then(res => {
        this.otherApis = res.data.filter(api => api.type.type === '认证授权')
        this.otherApiLoadSuccess = true
      })
      rolePermission.getRoleByRoleId(this.selectedRoleId).then(res => {
        this.roles = [res.data]
        if (!this.selectedRoleId && this.roles && this.roles.length > 0) {
          this.selectedRoleId = this.roles[0].id
          this.selectedTab = '' + this.roles[0].id
        }
        this.roleLoadSuccess = true
      })
    },
    /**
     * 加载权限状态
     */
    loadCheckStatus () {
      // this.selectedMenuRows = this.roles[0].menus
      this.selectedApis = this.roles[0].permissions.filter(permission => this.otherApis.filter(other => permission.id === other.id).length === 0)
    },
    /**
     * table表格 数据回显
     */
    otherCheckStatus () {
      this.selectedOtherApis = this.roles[0].permissions.filter(permission => this.otherApis.filter(other => permission.id === other.id).length > 0)
      this.$nextTick(() => {
        this.otherApis.filter(oa => this.selectedOtherApis.filter(sa => oa.id === sa.id).length > 0).forEach(row => {
          this.$refs.otherApiTable.toggleRowSelection(row, true)
        })
      })
    },
    resize () {
      this.clientHeight =
        this.mainContainerHeight -
        this.$refs.filterHeader.$el.clientHeight -
        this.$refs.footer.$el.clientHeight -
        50
    }
  },
  mounted () {
    this.selectedRoleId = this.$route.params.id
    if (this.selectedRoleId) {
      this.selectedTab = '' + this.selectedRoleId
      this.showCancel = true
    }
    this.ready()
    var that = this
    this.$nextTick(function () {
      that.resize()
    })
  }
}
</script>
