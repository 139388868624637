import api from '../api'

export class RolePermissionApi extends api {
  getAllMenuWithoutSuperMenu () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getAllMenusWithoutSuper`,
      method: 'get'
    })
  }

  getNoMenuApis () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getNoMenuApis`,
      method: 'get'
    })
  }

  updateRole (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/role`,
      method: 'put',
      data: form
    })
  }

  getRoleByRoleId (id) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/role/${id}`,
      method: 'get'
    })
  }
}


export default new RolePermissionApi('roleApi')
